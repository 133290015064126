var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.memberProvider)?_c('div',[_c('model-table',_vm._b({attrs:{"flat":"","provider":_vm.memberProvider,"crud":_vm.crud,"viewRoute":_vm.viewRoute,"addRoute":_vm.addRoute,"selection":'multiple',"contextCellName":"lastName","columns":_vm.columns},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"text-h5 q-pr-md"},[_vm._v("Beneficiaries")]),(
          _vm.$auth.permissions.admin.is || _vm.$auth.permissions.projectManager.is
        )?_c('q-toggle',{attrs:{"label":"My Cases","color":"primary","checked-icon":"check"},model:{value:(_vm.myCases),callback:function ($$v) {_vm.myCases=$$v},expression:"myCases"}}):_vm._e(),_c('q-btn',{staticClass:"q-ml-md",attrs:{"icon":"download","label":"Extract","flat":"","no-caps":""},on:{"click":function($event){_vm.openDialog = true}}})]},proxy:true},{key:"row-actions",fn:function(ref){
        var scope = ref.scope;
return [_c('q-btn',{attrs:{"icon":"work","flat":"","no-caps":""},on:{"click":function($event){return _vm.$router.push({
            name: 'CaseManagement',
            params: { id: scope.row.id }
          })}}},[_c('q-tooltip',[_vm._v(" Management ")])],1)]}}],null,false,3882812582)},'model-table',Object.assign({}, _vm.$attrs, _vm.$props),false)),(_vm.myCases)?_c('model-table',_vm._b({ref:"modelTable",attrs:{"flat":"","provider":_vm.referralProvider,"crud":_vm.referralCrud,"viewRoute":_vm.viewRoute,"contextCellName":"name","title":'Referrals',"columns":_vm.referralsColumns,"getName":function (i) { return i.referralId; }},scopedSlots:_vm._u([{key:"row-actions",fn:function(ref){
          var scope = ref.scope;
return [_c('q-btn',{attrs:{"icon":"check","flat":"","no-caps":""},on:{"click":function($event){return _vm.assignReferral(scope)}}},[_c('q-tooltip',[_vm._v(" Approve ")])],1)]}}],null,false,1640241306)},'model-table',Object.assign({}, _vm.$attrs, _vm.$props),false)):_vm._e(),(_vm.myCases)?_c('model-table',_vm._b({ref:"modelTable",attrs:{"flat":"","provider":_vm.prescreeningProvider,"crud":_vm.prescreeningCrud,"viewRoute":_vm.viewRoute,"contextCellName":"name","title":'Prescreenings',"columns":_vm.prescreeningColumns,"getName":function (i) { return i.prescreeningId; }},scopedSlots:_vm._u([{key:"row-actions",fn:function(ref){
          var scope = ref.scope;
return [_c('q-btn',{attrs:{"icon":"edit","flat":"","no-caps":""},on:{"click":function($event){_vm.selectedId = scope.row.id;
          _vm.viewDialog = true;}}},[_c('q-tooltip',[_vm._v(" View Prescreening ")])],1)]}}],null,false,1450093472)},'model-table',Object.assign({}, _vm.$attrs, _vm.$props),false)):_vm._e(),_c('q-dialog',{model:{value:(_vm.copyDialog),callback:function ($$v) {_vm.copyDialog=$$v},expression:"copyDialog"}},[_c('q-card',{staticStyle:{"min-width":"400px"}},[_c('q-card-section',{attrs:{"title":""}},[_c('div',{staticClass:"text-h6"},[_vm._v("Copy Questionnaire")])]),_c('q-card-section',{staticClass:"q-pt-none"},[_c('p',[_vm._v("Please give a new name for the copy.")]),_c('q-input',{attrs:{"filled":"","label":"Name"}})],1),_c('q-card-actions',{attrs:{"align":"right"}},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"flat":"","label":"Cancel"}}),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"Create","color":"primary"}})],1)],1)],1),_c('q-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c('beneficiaries-extractions',{attrs:{"provider":_vm.provider,"myCases":_vm.myCases}})],1),_c('q-dialog',{attrs:{"persistent":"","scrollable":"","full-width":""},model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}},[_c('prescreening-results',{attrs:{"id":_vm.selectedId,"close":true},on:{"close":function($event){_vm.viewDialog = false;
        _vm.$refs.modelTable.refresh();}}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }