



































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import { Location } from "vue-router";
import DataProvider from "@/lib/DataProvider";
import { HouseholdMember } from "@/data/models/HouseholdMember";
import BeneficiariesExtractions from "@/components/extractionForms/BeneficiariesExtractionsForm.vue";
import ICrudClient from "@/lib/ICrudClient";
import PrescreeningTableColumns from "@/data/columns/Prescreening";
import ReferralTableColumns from "@/data/columns/UserReferrals";
import PrescreeningResults from "@/components/forms/PrescreeningResults.vue";
import InMemoryActionClient from "@/lib/InMemory/InMemoryActionClient";
import { Prescreening } from "@/data/models/Questionnaires";

@Component({
  components: {
    ModelTable,
    BeneficiariesExtractions,
    PrescreeningResults
  }
})
export default class BeneficiariesTable extends Vue {
  @Prop()
  public provider!: DataProvider<HouseholdMember>;

  @Prop()
  public crud!: ICrudClient<HouseholdMember>;

  @Prop()
  public columns!: any[];

  @Prop()
  public viewRoute!: Location;

  @Prop()
  public addRoute!: Location;

  @Prop({ default: false })
  public currentUser!: boolean;

  private copyDialog = false;
  private openDialog = false;
  private myCases: any = false;
  private memberProvider: DataProvider<HouseholdMember> | null = null;
  private prescreeningProvider: DataProvider<Prescreening> | null = null;
  private referralProvider: DataProvider<any> | null = null;

  private prescreeningCrud = this.$service.crud.prescreenings;
  private referralCrud = this.$service.crud.referrals;
  private prescreeningColumns = PrescreeningTableColumns;
  private referralsColumns = ReferralTableColumns;
  private selectedId = null;
  private viewDialog = false;

  @Watch("myCases")
  onCasesChange() {
    this.currentUserChecked();
  }

  async currentUserChecked() {
    this.memberProvider = this.$service.providers.getMembersByPermission(
      this.myCases
    );
    if (this.myCases) {
      const prescreeningFilter = `
        ActionTaken ne 'intake' and
        AssignedToUser ne null and 
        AssignedToUser/FirstName eq '${this.$auth.userInfo.profile.firstName}' and 
        AssignedToUser/LastName eq '${this.$auth.userInfo.profile.lastName}'`;

      const referralFilter = `
        HouseholdMember/UserResponsible/FirstName ne '${this.$auth.userInfo.profile.firstName}' and 
        HouseholdMember/UserResponsible/LastName ne '${this.$auth.userInfo.profile.lastName}' and
        User/FirstName eq '${this.$auth.userInfo.profile.firstName}' and 
        User/LastName eq '${this.$auth.userInfo.profile.lastName}'`;

      this.prescreeningProvider = this.$service.providers.prescreeningMembersFilters(
        prescreeningFilter
      );

      this.referralProvider = this.$service.providers.referralMembersFilters(
        referralFilter
      );
    }
  }

  mounted() {
    const isAdminOrManager =
      this.$auth.permissions.admin.is ||
      this.$auth.permissions.projectManager.is;

    if (!isAdminOrManager) {
      this.myCases = true;
    }

    this.currentUserChecked();
    if (this.currentUser) {
      this.myCases = this.currentUser;
    }
  }

  private navigateToRoute(route: (id: string) => Location, id: any) {
    if (!route) {
      return;
    }
    this.$router.push(route(id));
  }

  private async assignReferral(scope: any) {
    const item = scope.row;
    try {
      await this.referralCrud.saveAsync(item);
      this.$q.notify({
        message: "Item saved successfully!",
        color: "green-4",
        timeout: 2000
      });
      this.myCases = true;
      this.currentUserChecked();
    } catch (error) {
      console.log(error);
      this.$q.notify({ message: error, color: "red-4" });
    }
  }
}
