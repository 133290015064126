





































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "@/components/FormContainer.vue";
import DatePicker from "@/components/DatePicker.vue";
import { consultations } from "@/data/mock/Activities";
import { warn } from "vue-class-component/lib/util";

@Component({
  components: {
    FormContainer,
    DatePicker
  }
})
export default class BeneficiariesExtractions extends Vue {
  @Prop()
  public provider!: any;

  @Prop()
  public myCases!: boolean;

  private legalStatuses: any = [];
  private intakes: any = [];
  private users: any = [];
  private projects: any = [];
  private genders: any = [];
  private nationalities: any = [];
  private filters: any = null;

  @Watch("filters", { deep: true })
  onItemChange() {
    if (!this.filters) {
      this.initializeItem();
    }
  }

  async mounted() {
    const proj = (await this.$service.providers.extractionProjects.fetchItemsAsync()).items;
    this.projects = proj.map((i: any) => i.title)

    const allUsers = (await this.$service.providers.users.fetchItemsAsync()).items;
    this.users = allUsers.map((i: any) => i.firstName + " " + i.lastName);

    const allGenders = (await this.$service.providers.genders.fetchItemsAsync()).items;
    this.genders = allGenders.map((i: any) => i.value);

    const allNationalities = (await this.$service.providers.nationalities.fetchItemsAsync()).items;
    this.nationalities = allNationalities.map((i: any) => i.value);

    const allLegalStatuses = (await this.$service.providers.legalStatuses.fetchItemsAsync()).items;
    this.legalStatuses = allLegalStatuses.map((i: any) => i.value);
    this.initializeItem();
  }

  getDinstinct(arr: any) {
    return Array.from(
      arr.filter(
        (value: any, index: any, self: any) => self.indexOf(value) === index
      )
    );
  }

  initializeItem() {
    this.filters = {
      users: [],
      projects: [],
      genders: [],
      minAge: "",
      maxAge: "",
      nationalities: [],
      legalStatuses: [],
      assignedToProject: null,
      dateCreated: null,
    };
    if (this.myCases) {
      this.filters.users.push(`${this.$auth.userInfo.profile.firstName} ${this.$auth.userInfo.profile.lastName}`);
    }
  }
}
